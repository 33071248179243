import {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { 
    Tabs,
    Tab,
    Typography,
    Box
} from '@mui/material';
import PropTypes from 'prop-types';
import {
    getSubissionsList,
    getProsList,
    getOhnosList,
    checkIsAdmin
} from '../api/index.js';
import SubmissionsList from './SubmissionsList.js';
import ProsList from './ProsList.js';
import OhNosList from './OhNosList.js';

const TabPanel = ({ children, value, index, ...other }) => {
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AdminDash = ({token, setToken}) => {
    
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const [submissionsList, setSubmissionsList] = useState([]);
    const [prosList, setProsList] = useState([]);
    const [ohnosList, setOhnosList] =useState([]);
    const [adminLoggedIn, setAdminLoggedIn] = useState(false);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
        getInitialLists();
    };

    const checkForAdminStatus = async () => {
        const isAdmin = await checkIsAdmin(token);
        console.log(isAdmin);
        if (isAdmin.isAdmin === true) {
            setAdminLoggedIn(true);
        } else {
            navigate('/PlanetSoup/Login');
        }
    }

    const getInitialLists = async () => {
        const submissions = await getSubissionsList(token);
        setSubmissionsList(submissions);
        const pros = await getProsList(token);
        setProsList(pros);
        const ohnos = await getOhnosList(token);
        setOhnosList(ohnos);
    }

    // useEffect
    useEffect(() => {
        checkForAdminStatus();
        getInitialLists();
    }, []);

    return (adminLoggedIn ? 
            <div className="admin-dash">
                <h2>Admin Dashboard</h2>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs centered value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Submissions" {...a11yProps(0)} />
                        <Tab label="Pro Cards" {...a11yProps(1)} />
                        <Tab label="Oh No Cards" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <SubmissionsList submissionsList={submissionsList} setSubmissionsList={setSubmissionsList} token={token} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProsList prosList={prosList} setProsList={setProsList} token={token} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <OhNosList ohnosList={ohnosList} setOhnosList={setOhnosList} token={token} />
                    </TabPanel>
                </Box> 
            </div>
        : null 
    )
}

export default AdminDash;