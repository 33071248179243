import { 
    Container,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ClosedBeta = () => {

    const DiscordButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#7289d9'),
        backgroundColor: '#7289d9',
        '&:hover': {
          backgroundColor: '#4e5d94',
        },
    }));

    return (
        <Container maxWidth='sm'>
            <div className='closed-beta-page'>
                <h1>What a Catch! is Currently in Beta</h1>
                <p>If you'd like to help aSpecificEgg in her work to develop the What a Catch! game, join our Discord! Egg will be pushing out release updates, gathering bugs and feature requests, giving sneak peeks at upcoming features in development, and more.</p> 
                    
                <DiscordButton variant="contained" href='https://discord.gg/BZ8D8AW4jU'>Join the Discord</DiscordButton>

                <p>Already a Discord member? Use the top navigation links to play the game or submit new cards.</p>
            </div>
        </Container>
    )
}

export default ClosedBeta;