import { useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import { 
    updateCardContent,
    getProsList,
    activateCard,
    deactivateCard
} from '../api';
import DisplayCardText from './CardText';
import FlagsWidget from './FlagsWidget';

const ProCardDialog = ({ openDialog, setOpenDialog, setProsList, cardDialogData, token }) => {

    const [cardTitle, setCardTitle] = useState(cardDialogData.title);
    const [cardType, setCardType] = useState('pro');
    const [cardBullets, setCardBullets] = useState(cardDialogData.bullets);
    const [cardMature, setCardMature] = useState(cardDialogData.mature);
    const [cardAuthor, setCardAuthor] = useState(cardDialogData.author);
    const [cardIsActive, setCardIsActive] = useState(cardDialogData.isActive);

    const cardData = {
        title: cardTitle,
        bullets: cardBullets,
        mature: cardMature,
        author: cardAuthor,
        type: cardType,
        flags: cardDialogData.flags
    }
    const flagTypes = [];

    const handleClose = async () => {
        setOpenDialog(false);
        const updatedProsList = await getProsList(token);
        setProsList(updatedProsList);
    };

    const handleChangeText = (e, setState) => {
        setState(e.target.value);
    }

    const handleBulletsChange = (e, setState, index) => {
        cardBullets[index] = e.target.value;
        setState([...cardBullets]);
    }

    const handleChangeSelect = (e) => {
        setCardMature(e.target.value);
    }

    const handleDeactivate = async () => {
        const deactivatedCard = await deactivateCard(token, 'pro', cardDialogData.id);
        if (deactivatedCard.isActive === false) {
            setCardIsActive(false);
        }
        handleClose();
    }

    const handleActivate = async () => {
        const activatedCard = await activateCard(token, 'pro', cardDialogData.id);
        if (activatedCard.isActive === true) {
            setCardIsActive(true);
        }
        handleClose();
    }

    const handleSubmit = async () => {
        const updatedCard = await updateCardContent(token, cardType, cardDialogData.id, cardData)
        console.log(updatedCard)

        handleClose();
    }

    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'md'} fullWidth={true} >
            <DialogTitle>Edit Card Details</DialogTitle>
            <DialogContent className={'create-a-card'}>

                    <FormControl sx={{
                        m: 2, 
                        minWidth: 375,
                        '& > :not(style)': { m: 0.5}
                    }}>
                        <TextField fullWidth id='card-title' label='Card Title' variant='outlined' required value={cardTitle} onChange={(e) => handleChangeText(e, setCardTitle)} />
                        <TextField fullWidth id='card-bullet-1' label='First Bullet' variant='outlined' required value={cardBullets[0]} onChange={(e) => handleBulletsChange(e, setCardBullets, 0)} />
                        <TextField fullWidth id='card-bullet-2' label='Second Bullet' variant='outlined' required value={cardBullets[1]} onChange={(e) => handleBulletsChange(e, setCardBullets, 1)} />
                        <TextField fullWidth id='card-bullet-3' label='Third Bullet' variant='outlined' required value={cardBullets[2]} onChange={(e) => handleBulletsChange(e, setCardBullets, 2)} />
                        <TextField fullWidth id='card-bullet-4' label='Fourth Bullet (Optional)' variant='outlined' disabled={!cardBullets[2]} value={cardBullets[3]} onChange={(e) => handleBulletsChange(e, setCardBullets, 3)} />
                        <TextField fullWidth id='card-bullet-5' label='Fifth Bullet (Optional)' variant='outlined' disabled={!cardBullets[3]} value={cardBullets[4]} onChange={(e) => handleBulletsChange(e, setCardBullets, 4)} />
                        <FormGroup>
                            <FormLabel component="legend">Is this card spicy (i.e. for mature audiences only)?</FormLabel>
                            <Select
                            id="mature-select"
                            value={cardMature}
                            label="Mature"
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(e) => handleChangeSelect(e)}
                            >
                                <MenuItem value={true}>Yes, 18+ spice right here!</MenuItem>
                                <MenuItem value={false}>No, absolutely no spice</MenuItem>
                            </Select>
                        </FormGroup>


                        <TextField fullWidth id='card-author' label='Author' variant='outlined' value={cardAuthor} onChange={(e) => handleChangeText(e, setCardAuthor)} />
                    </FormControl>

                    <div className={'dialog-card-preview'}>
                        <div className={cardType}>
                            <DisplayCardText cardData={cardData} cardType={cardType} flagTypes={[]} />
                            <h4 className={"card-preview"}>Card Preview</h4>
                        </div>
                    </div>
            </DialogContent>
            <FlagsWidget cardDialogData={cardData} token={token} />
            <DialogActions>
                {cardIsActive ? <Button onClick={handleDeactivate}>Deactivate</Button> : <Button onClick={handleActivate}>Activate</Button> }
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleSubmit}>Update</Button>
            </DialogActions>
      </Dialog>
    )

}

export default ProCardDialog;