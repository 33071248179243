
const GetManatee = ({ selectCards, spicyPerk, spicyQuirk, setSpicyPerk, setSpicyQuirk, adminLoggedIn }) => {
    
    const handleChange = (e, setState) => {
        setState(e.target.checked)
    }
    
    return (
        <div className="game-ui">
            <button type="button" className="play-button" onClick={selectCards}>Get New Date</button>
            
            { adminLoggedIn ? <div id="spicy-options" className="dark-mode">
                <div>
                    <input type="checkbox" id="spicy-perk" name="spicy-perk" value={spicyPerk} onChange={(e) => handleChange(e, setSpicyPerk)} />
                    <label className="checkmark"> Spicy Pro</label>
                </div>

                <div>
                    <input type="checkbox" id="spicy-quirk" name="spicy-quirk" value={spicyQuirk} onChange={(e) => handleChange(e, setSpicyQuirk)} />
                    <label> Spicy Oh No</label>
                </div>
            </div> : null }
        </div>

    )
}

export default GetManatee;