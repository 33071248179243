import { useState, useEffect } from 'react';
import GetManatee from './GetManatee';
import DrawNewCard from './DrawNewCard';
import Cards from './Cards';
import { 
    Alert,
    AlertTitle,
    Box,
    CircularProgress 
} from '@mui/material';

import { 
    drawNewCard,
    checkIsAdmin,
    getFlagTypes
} from '../api';

const GameBoard = ({ token }) => {
    const initialState = {
        proRevealed: false,
        ohnoRevealed: false,
        cardsChosen: false
    }

    const [gameboard, setGameboard] = useState(initialState);
    const [perk, setPerk] = useState({});
    const [quirk, setQuirk] = useState({});
    const [spicyPerk, setSpicyPerk] = useState(false);
    const [spicyQuirk, setSpicyQuirk] = useState(false);
    const [adminLoggedIn, setAdminLoggedIn] = useState(false);
    const [cardSelectionInProcess, setCardSelectionInProcess] = useState(false);
    const [cardError, setCardError] = useState(false);
    const [flagTypes, setFlagTypes] = useState([]);

    const checkForAdminStatus = async () => {
        const isAdmin = await checkIsAdmin(token);
        console.log(isAdmin);
        if (isAdmin.isAdmin === true) {
            setAdminLoggedIn(true);
        }
    }

    const initFlagTypes = async () => {
        const flagTypesList = await getFlagTypes();
        if (flagTypesList.length > 0) {
            setFlagTypes(flagTypesList);
        }
    }

    const generateNewCard = (cardList) => {
        return cardList[10000+(Math.ceil(Math.random()*Object.keys(cardList).length))]
    }

    const selectCards = async () => {
        setGameboard({...initialState, cardsChosen: false});
        setCardSelectionInProcess(true);
        let newPerk = await drawNewCard('pro', spicyPerk);
        let newQuirk = await drawNewCard('ohno', spicyQuirk);
        
        setPerk({...newPerk});
        setQuirk({...newQuirk});
        console.log(newPerk);
        console.log(newQuirk);
        setCardSelectionInProcess(false);
        if (newPerk.id && newQuirk.id) {
            setGameboard({...initialState, cardsChosen: true});
        } else {
            setCardError(true);
        }
    }

    useEffect(() => {
        checkForAdminStatus();
        initFlagTypes();
    }, [])

    return (
        <div className='game-board'>
            <GetManatee selectCards={selectCards} setSpicyPerk={setSpicyPerk} spicyPerk={spicyPerk} spicyQuirk={spicyQuirk} setSpicyQuirk={setSpicyQuirk} adminLoggedIn={adminLoggedIn} />
            { cardError ?
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                }}>
                    <Alert severity="error" sx={{ 
                        width: '700px',
                        textAlign: 'left'
                    }}>
                        <AlertTitle>Uh Oh - Something went wrong!</AlertTitle>
                        An error occurred while getting the card data. Try reloading the page and getting a new date. If you continue to experience errors, please reach out to the <a href='mailto:aspecificegg@gmail.com'>site administrator</a>.
                    </Alert>
                </Box>
                : null
            }
            { cardSelectionInProcess ? 
                <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}>
                    <CircularProgress color="secondary"/>
                </Box>
                : null 
            }
            { gameboard.cardsChosen ? <Cards gameboard={gameboard} setGameboard={setGameboard} perk={perk} quirk={quirk} flagTypes={flagTypes} /> : null }
            { gameboard.cardsChosen ? <DrawNewCard spicyPerk={spicyPerk} spicyQuirk={spicyQuirk} setPerk={setPerk} setQuirk={setQuirk} gameboard={gameboard} setGameboard={setGameboard} /> : null }
        </div>
    )
}

export default GameBoard;