import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Header = () => {
    return (
        <header className='game-header'>
            <Link to='/'><h1>What a Catch! <span className='app-stage-label'>Beta</span></h1></Link>

            <div className='header-nav-links'>
                <Button variant='text' color='secondary' href='/play'>Play</Button>
                <Button variant='text' color='secondary' href='/about'>About</Button>
                <Button variant='text' color='secondary' href='/support'>Support</Button>
                <Button variant='text' color='secondary' href='/submit'>Suggest a Card</Button>
            </div>
        </header>
    )
}

export default Header;