import { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {
    deleteFlag
} from '../api';

const FlagsWidget = ({cardDialogData, token}) => {

    const [flagsList, setFlagsList] = useState(cardDialogData.flags);

    const handleClick = async (flagId, token, index) => {
        try {
            const deletedFlag = await deleteFlag(token, flagId);
            console.log(deletedFlag);
        } catch (error) {
            console.log(error);
        } finally {
            const updatedFlagsList = [...flagsList];
            updatedFlagsList[index].checked = true;
            setFlagsList(updatedFlagsList);
        }
    }


    return (
        <Box className="flags-list" sx={{
            width: .8,
            mx: 5
         }} >
            {flagsList.length ? 
            <>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    Flags
                </Typography>
                <List >
                    {flagsList.map((flag, index) => 
                        <ListItem
                            key={flag.id}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleClick(flag.id, token, index)}>
                                    { flag.checked ? 
                                        <CheckBoxIcon /> 
                                        : <CheckBoxOutlineBlankIcon />
                                    }
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={flag.type}
                            />
                        </ListItem>
                    )}
                </List>
            </>
            : null}
        </Box>
    )

}

export default FlagsWidget;