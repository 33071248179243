import { useState } from 'react';
import { 
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography 
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AboutTeam from './AboutTeam';

const About = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container maxWidth='md'>
            <div className='about-page'>
                <h1>Learn about What a Catch!</h1>
                <div className='about-content'>
                    <h2>How to Play the Game</h2>
                    <p>What a Catch is a card-based party game where you consider your greatest desires in dating... and what you would tolerate in exchange.</p> 
                        
                    <p>Players take turns drawing 2 cards to learn about their prospective date. Each date is represented by a Pro card and an Oh No card (i.e. a positive and a negative characteristic or backstory). Once both traits are revealed, all other players get to guess whether the player whose turn it is would "catch" or "release" their date.</p>

                    <h3>The Team</h3>
                    <AboutTeam />

                    <h3>Development</h3>
                    <p>What a Catch! is in development by Specific Egg Media, and is currently in a closed beta stage. </p>
                    <p>The frontend was built using React.js and Material UI. All of the game's card content is served up from a REST API built with Node.js and Express, pulling data from a PostgreSQL database. </p>

                    <h3>Community Driven Content</h3>
                    <p>Many of the cards you'll see in What a Catch! were written by players like you! </p>
                    <p>What a Catch! was originally built by Egg as a game to play with her Twitch community. This project would not be possible without the creativity, engagement, and brilliant minds of The Scramble members. ❤︎ </p>
                    <p>If you have ideas and are interested in suggesting a card (or two, or five!) for the game, click <Link to='/submit'>"Suggest a Card"</Link> here or in the navigation bar above. </p>
                    <p>All card suggestions undergo a review and editing process, which may take up to a week. Check back regularly to see if you draw one of your own cards in the game! </p>

                    <h2>FAQs</h2>
                    <div className='faqs'>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ backgroundColor: '#9F71B5' }}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                            <Typography sx={{ width: '60%', flexShrink: 0, fontWeight: 600 }}>
                                How do What a Catch cards get published?
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <p>All cards in What a Catch were written by players of the game. New cards can be suggested with the <a href="/submit">"Suggest a Card" form</a>. But new suggested cards don't go directly into circulation in the game.</p>

                                <p>Instead, new cards go into a submission queue and are carefully reviewed and edited prior to being published into the game.</p>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ backgroundColor: '#9F71B5' }}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            >
                            <Typography sx={{ width: '60%', flexShrink: 0, fontWeight: 600 }}>Who can play What a Catch?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <p>What a Catch is designed for anyone ages 13+ to play. Cards are written such that players of all genders and sexual orientations can play: all cards are written to be gender neutral.</p>

                                <p>What a Catch content is also edited and moderated to ensure the game is inclusive to a diverse audience. Racism, sexism, homophobia, transphobia, as well as other prejudices and bigoted views are not welcome among the user-generated content and cards exhibiting these attitudes will not be published.</p>

                                <p>That said, everyone has their own bias, including the game developer. This is why we've implemented a flagging system, to catch any cards that slip through the editorial process. If you encounter a card that you find objectionable or that doesn't align with the game's values, you can flag it for editorial review, using the flag icon in the bottom right corner of the card.</p>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ backgroundColor: '#9F71B5' }}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            >
                            <Typography sx={{ width: '60%', flexShrink: 0, fontWeight: 600 }}>
                                What do I do if I find an error in a card?
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                Cards can be flagged for editorial review for a variety of problems or errors. If you discover that a card needs to be further edited, you can click the flag icon in the bottom right corner of the card and select from a list of possible errors.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ backgroundColor: '#9F71B5' }}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            >
                            <Typography sx={{ width: '60%', flexShrink: 0, fontWeight: 600 }}>
                                I have an idea to improve or expand What a Catch, how do I make a suggestion to the game developer?
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                What a Catch has a Discord server dedicated to beta testing new features, reporting bugs and making suggestions for the game. You can access the invite link to the Discord server on the <a href="/support">"Support" page</a>.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default About;