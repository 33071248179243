import { 
    Container,
    Button
} from '@mui/material';
import StepOne from '../Images/WAC-step1.png';
import StepTwo from '../Images/WAC-step2.png';
import StepThree from '../Images/WAC-step3.png';
import HamstonIntro from '../Images/v2/Hamston_Intro.png';
import HamstonPro from '../Images/v2/Hamston_Pro_Ex.png';
import HamstonOhNo from '../Images/v2/Hamston_Oh_No_Ex.png';
import CardHeadingPro from '../Images/v2/wac-card-heading-pro.png';
import CardHeadingOhNo from '../Images/v2/wac-card-heading-ohno.png';


const FrontPage = () => {


    return (
        <Container maxWidth='xl' disableGutters="true" >
            <div className='front-page-hero'>
                <div className='front-page-hero-headline'>
                    <p>Welcome to</p>
                    <h1>What a Catch!</h1>
                </div>
                <div className='front-page-hero-buttons'>
                    <Button variant="contained" size="large" href='/play'>Play Game</Button>
                    <Button variant="contained" size="large" href='/about'>Learn More</Button>
                </div>
            </div>
            <Container maxWidth='md' sx={{ my: 5 }} >
                <div className='front-page row'>
                    <img className='front-page image-left' src={HamstonIntro} alt="Hamston the hamster intro graphic - card intros" />
                    <div className='front-page text-right'>
                        <h3>What a Catch is the game where you consider your greatest desires in dating... and what you would tolerate in exchange.</h3>
                        <p>You'll draw two cards: a "Pro" and an "Oh No" to learn the virtues and foibles of your would-be date.</p>
                    </div>
                </div>
                <div className='front-page row'>
                    <div className='front-page right-image step-two-desktop'>
                        <h3>Each round you'll be dazzled by your date's charm, skills, or mythical powers.</h3>
                        <img className='front-page card-heading' src={CardHeadingPro} alt="What a Catch playing card in green that reads: 'They are a 5-star chef'" />
                        <p>The "Pro" card will reveal a positive quality about your date. They might have an impressive career, endless charm, or even superpowers!</p>
                    </div>
                    <img className='front-page image-right' src={HamstonPro} alt="Hamston the hamster intro graphic - pro card explanation" />
                    <div className='front-page right-image step-two-mobile'>
                        <h3>Each round you'll be dazzled by your date's charm, skills, or mythical powers.</h3>
                        <img className='front-page card-heading' src={CardHeadingPro} alt="What a Catch playing card in green that reads: 'They are a 5-star chef'" />
                        <p>The "Pro" card will reveal a positive quality about your date. They might have an impressive career, endless charm, or even superpowers!</p>
                    </div>
                </div>
                <div className='front-page row'>
                    <img className='front-page image-left' src={HamstonOhNo} alt="Hamston the hamster intro graphic - oh no explanation" />
                    <div className='front-page text-right'>
                        <h3>But remember: no matter how great they sound at first... there is always a catch!</h3>
                        <img className='front-page card-heading' src={CardHeadingOhNo} alt="What a Catch playing card in purple that reads: 'Theyare a human-sized, walking, talking banana'" />
                        <p>The "Oh No" is the catch, something unfortunate or unpleasant about your date.</p> 
                        <p>Once both are revealed it's time for all other players to guess: would you still reel them in... or release your catch?</p>
                    </div>
                </div>
            </Container>
        </Container>
    )
}

export default FrontPage;