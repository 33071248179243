import { motion } from 'framer-motion';
import DisplayCardText from './CardText';
// import ProCard from '../Images/pro-card.png';
// import OhNoCard from '../Images/ohno-card.png';
import ProCard from '../Images/v2/Pro-Card-v2.png';
import OhNoCard from '../Images/v2/Oh-No-Card-v2.png';

const Cards = ({gameboard, setGameboard, perk, quirk, flagTypes}) => {

    const handleChange = async (e) => {
        setGameboard({
            ...gameboard,
            [e.target.name]: true
        })
    }

    return (
        <div className='cards'>
                <div className="pro">
                    { gameboard.proRevealed ? 
                    <DisplayCardText cardData={perk} cardType={'pro'} flagTypes={flagTypes} /> : 
                    <motion.img 
                        whileHover={{
                            scale: 1.05,
                            transition: { duration: 0.5 },
                        }} 
                        animate={{ x: 0, scale: [1.05, 1.05, 1.05, 1] }}
                        initial={{ x: -300, scale: 1.05 }}
                        transition={{ ease: "easeOut", duration: 0.5 }}
                        src={ProCard} className="game-card" alt="Pro Card" name="proRevealed" onClick={(e) => handleChange(e)} /> 
                    }
                </div>
                <div className="ohno">
                    { gameboard.ohnoRevealed ? 
                    <DisplayCardText cardData={quirk} cardType={'ohno'} flagTypes={flagTypes} /> : 
                    <motion.img 
                        whileHover={{
                            scale: 1.05,
                            transition: { duration: 0.5 }
                        }} 
                        animate={{ x: 0, scale: [1.05, 1.05, 1.05, 1] }}
                        initial={{ x: 300, scale: 1.05 }}
                        transition={{ ease: "easeOut", duration: 0.5 }}
                        src={OhNoCard} className="game-card" alt="Oh No Card" name="ohnoRevealed" onClick={(e) => handleChange(e)} /> 
                    }
                </div>
            </div>
    )
}

export default Cards;