import { useState } from 'react';
import DisplayCardText from './CardText';
import { 
    FormControl,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    FormLabel,
    FormGroup,
    Button,
    Alert,
    AlertTitle,
    Select,
    MenuItem
} from '@mui/material';
import { submitCard } from '../api';

const CreateACard = () => {

    const [type, setType] = useState('pro');
    const [title, setTitle] = useState('');
    const [bullets, setBullets] = useState([]);
    const [mature, setMature] = useState(false);
    const [author, setAuthor] = useState('');
    const [formValidated, setFormValidated] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const cardData = {
        type,
        title,
        bullets,
        mature,
        author
    }

    const handleChangeText = (e, setState) => {
        setState(e.target.value);
        handleFormValidation();
    }

    const handleTypeChange = (e, newType) => {
        if (newType.length) {
            setType(newType);
        }
    };

    const handleBulletsChange = (e, setState, index) => {
        bullets[index] = e.target.value;
        setState([...bullets]);
        handleFormValidation();
    }

    const handleMatureChange = (e) => {
        setMature(e.target.checked);
    }

    const handleChangeSelect = (e) => {
        setMature(e.target.value);
    }

    const handleSubmit = async () => {
        const results = await submitCard({type, title, bullets, mature, author});
        console.log(results);

        if (results.message === 'duplicate key value violates unique constraint "submissions_title_key"') {
            setErrorMessage('A card with this title has already been submitted. Please submit a card with a unique title.');
            setSubmitSuccess('Error');
        } else if (results.id) {
            setSubmitSuccess('Success');
            setTitle('');
            if (bullets.length === 3) {
                setBullets(['', '', '']);
            } else if (bullets.length === 4) {
                setBullets(['', '', '',''])
            } else if (bullets.length === 5) {
                setBullets(['', '', '','',''])
            }
            setMature(false);
            setAuthor('');
        } else {
            setErrorMessage('Uh oh, something went wrong with your submission! Try editing your card content or refreshing the page and submitting your card again.')
        }
    }

    const handleFormValidation = () => {
        if (title.length >= 1 && bullets[0].length && bullets[1].length && bullets[2].length) {
            setFormValidated(true);
        } else {
            setFormValidated(false);
        }
    }

    return (
        <div className='create-a-card'>
            <div className='create-a-card-form'>
                <h2>Suggest a Card</h2>
                <FormControl sx={{ 
                    width: 350,
                    '& > :not(style)': { m: 0.5}
                }}>
                    <FormLabel component="legend" color='secondary' >Set the card type</FormLabel>
                    <ToggleButtonGroup
                        value={type}
                        exclusive
                        onChange={handleTypeChange}
                        aria-label="card type"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}
                    >
                        <ToggleButton value="pro" aria-label="pro card">
                            Pro
                        </ToggleButton>
                        <ToggleButton value="ohno" aria-label="ohno card">
                            Oh No
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <TextField 
                        fullWidth id='card-title' 
                        label='Card Title' 
                        variant='outlined' 
                        color='secondary' 
                        required 
                        value={title} 
                        onChange={(e) => handleChangeText(e, setTitle)} 
                    />
                    <TextField 
                        fullWidth 
                        id='card-bullet-1' 
                        label='First Bullet' 
                        variant='outlined' 
                        color='secondary'
                        required 
                        value={bullets[0]} 
                        onChange={(e) => handleBulletsChange(e, setBullets, 0)} 
                    />
                    <TextField 
                        fullWidth 
                        id='card-bullet-2' 
                        label='Second Bullet' 
                        variant='outlined' 
                        color='secondary'
                        required 
                        value={bullets[1]} 
                        onChange={(e) => handleBulletsChange(e, setBullets, 1)} 
                    />
                    <TextField 
                        fullWidth 
                        id='card-bullet-3' 
                        label='Third Bullet' 
                        variant='outlined' 
                        color='secondary'
                        required 
                        value={bullets[2]} 
                        onChange={(e) => handleBulletsChange(e, setBullets, 2)} 
                    />
                    <TextField 
                        fullWidth 
                        id='card-bullet-4' 
                        label='Fourth Bullet (Optional)' 
                        variant='outlined' 
                        color='secondary'
                        disabled={!bullets[2]} 
                        value={bullets[3]} 
                        onChange={(e) => handleBulletsChange(e, setBullets, 3)} 
                    />
                    <TextField 
                        fullWidth 
                        id='card-bullet-5' 
                        label='Fifth Bullet (Optional)' 
                        variant='outlined' 
                        color='secondary'
                        disabled={!bullets[3]} 
                        value={bullets[4]} 
                        onChange={(e) => handleBulletsChange(e, setBullets, 4)} 
                    />

                    <FormGroup>
                        <FormLabel component="legend">Is this card spicy (i.e. for mature audiences)?</FormLabel>
                        <Select
                        id="mature-select"
                        value={mature}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => handleChangeSelect(e)}
                        >
                            <MenuItem value={true}>Yes, 18+ spice right here!</MenuItem>
                            <MenuItem value={false}>No, absolutely no spice</MenuItem>
                        </Select>
                    </FormGroup>


                    <TextField 
                        fullWidth 
                        id='card-author' 
                        label='Author' 
                        variant='outlined' 
                        color='secondary'
                        value={author} 
                        onChange={(e) => handleChangeText(e, setAuthor)} 
                    />
                    <Button 
                        variant="contained" 
                        disabled={!formValidated} 
                        fullWidth 
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </FormControl>
                { submitSuccess === 'Success' ?
                    <Alert 
                        severity="success" 
                        onClose={() => {setSubmitSuccess('')}}
                        variant='outlined'
                        sx={{
                            width: 400,
                            m: 0.5,
                            textAlign: 'left'
                        }}
                    >
                        <AlertTitle>Thank you for submitting a card idea!</AlertTitle>
                        All card submissions are reviewed and edited before being published to the game. Keep an eye out for your card soon!
                    </Alert> 
                    : null
                }
                { submitSuccess === 'Error' ?
                    <Alert 
                        severity="error" 
                        onClose={() => {setSubmitSuccess('')}}
                        variant='outlined'
                        sx={{
                            width: 400,
                            m: 0.5,
                            textAlign: 'left'
                        }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert> 
                    : null
                }
            </div>
            <div className={type}>
                <DisplayCardText cardData={cardData} cardType={type} flagTypes={[]}/>
                <h4 className="card-preview">Card Preview</h4>
            </div>
        </div>
    );

}

export default CreateACard;