import { useState } from 'react';
import { motion } from 'framer-motion';
import Fab from '@mui/material/Fab';
import FlagIcon from '@mui/icons-material/Flag';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';

import { 
    createFlag 
} from '../api';

const DisplayCardText = ({cardData, cardType, flagTypes}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };

    const handleFlagTypeClick = async (cardType, cardData, flagType) => {
        let cardTypeUpdated = '';
        if (cardType === 'pro') {
            cardTypeUpdated = 'perk'
        } else if (cardType === 'ohno') {
            cardTypeUpdated = 'quirk'
        }

        const newFlag = await createFlag(cardTypeUpdated, cardData.id, flagType.id);
        console.log(newFlag);
        setAnchorEl(null);
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <motion.div
            animate={{ scale: 1 }}
            initial={{ scale: 1.05 }}
            transition={{ ease: "easeOut", duration: 0.7 }} 
            className="card-text dark-mode">

            <Box sx={{
                minHeight: 500,
                position: 'relative',
                }}>

                <div className="main-card-content">
                    <div className="card-header">
                        {cardType === 'pro' ? <h4>Pro</h4> : <h4>Oh No</h4> }
                    </div>
                    <h2>{cardData.title}</h2>
                    <ul>
                        {cardData.bullets.filter((bullet) => bullet !== "").map((bullet, index) => {
                            return <li key={index} >{bullet}</li>;
                        })}
                    </ul>
                </div>

                <div className="card-attribution">
                    {cardData.author ? <p>Card by: {cardData.author}</p> : null}
                </div>

                {flagTypes.length > 0 ? 
                <>
                    <Fab 
                        color="ivory" 
                        aria-label="flag" 
                        size="small" 
                        sx={{
                            position: 'absolute',
                            top: 435,
                            right: 0,
                        }}
                        id="flag-button"
                        aria-controls={open ? 'flags-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <FlagIcon fontSize="small" sx={{color: "#9F71B5"}} />
                    </Fab>
                    <Menu
                        id="flags-menu"
                        MenuListProps={{
                        'aria-labelledby': 'flag-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        {flagTypes.map((option) => (
                        <MenuItem key={option.id} onClick={() => {handleFlagTypeClick(cardType, cardData, option)}}>
                            {option.type}
                        </MenuItem>
                        ))}
                    </Menu>
                    <Snackbar
                        anchorOrigin={{ 
                            vertical: 'bottom', 
                            horizontal: 'right' 
                        }}
                        open={snackbarOpen}
                        onClose={handleSnackbarClose}
                        message="Thank you for your feedback!"
                        autoHideDuration={6000}
                    />
                </>
                : null}
            </Box>
        </motion.div>
    )
}

export default DisplayCardText