import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { 
    FormControl,
    TextField,
    Button
} from '@mui/material';
import { 
    loginAdmin,
    checkIsAdmin
 } from '../api';

const AdminLogin = ({token, setToken}) => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [formValidated, setFormValidated] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChangeText = (e, setState) => {
        setState(e.target.value);
        handleFormValidation();
    }

    const handleSubmit = async () => {
        const loginResponse = await loginAdmin(username, password);
        const userIsAdmin = await checkIsAdmin(loginResponse.token);
        if (loginResponse.token && userIsAdmin) {
            setToken(loginResponse.token);
            localStorage.setItem('wac-token', loginResponse.token);
            setLoginSuccess('Success');
            navigate('/PlanetSoup/Dashboard');
        }
    }

    const handleFormValidation = () => {
        if (username.length > 0 && password.length > 0) {
            setFormValidated(true);
        } else {
            setFormValidated(false);
        }
    }

    return (
        <div className='admin-login'>
            <FormControl 
                className='admin-login-form'
                sx={{ 
                    width: 400,
                    '& > :not(style)': { m: 0.5}
            }}>
                <TextField 
                    fullWidth 
                    id='username' 
                    label='Username' 
                    variant='outlined' 
                    required 
                    value={username} 
                    onChange={(e) => handleChangeText(e, setUsername)} 
                />
                <TextField 
                    fullWidth 
                    id='password' 
                    label='Password' 
                    variant='outlined' 
                    type='password'
                    required 
                    value={password} 
                    onChange={(e) => handleChangeText(e, setPassword)} 
                />
                <Button variant="contained" disabled={!formValidated} fullWidth onClick={handleSubmit}>Submit</Button>
            </FormControl>
        </div>
    )
}

export default AdminLogin;