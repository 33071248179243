import { drawNewCard } from "../api";

const DrawNewCard = ({ spicyPerk, spicyQuirk, setPerk, setQuirk, gameboard, setGameboard }) => {

    const selectCard = async (cardType, spicySetting, setCard) => {

        const newCard = await drawNewCard(cardType, spicySetting);
        setCard({...newCard});

        if (cardType === 'pro') {
            setGameboard({...gameboard, proRevealed: false})
        } else if (cardType === 'ohno') {
            setGameboard({...gameboard, ohnoRevealed: false})
        }
    }

    return (
        <div className="redraw-cards-buttons">
            <button type="button" className="draw-card-button" onClick={() => selectCard('pro', spicyPerk, setPerk)}>Draw New Pro</button>
            <button type="button" className="draw-card-button" onClick={() => selectCard('ohno', spicyQuirk, setQuirk)}>Draw New Oh No</button>
        </div>
    )

}

export default DrawNewCard;