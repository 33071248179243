import { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    ToggleButton,
    ToggleButtonGroup,
    FormGroup,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import { 
    updateSubmissionStatus,
    getSubissionsList,
    createNewCard
} from '../api';
import DisplayCardText from './CardText';

const SubmissionDialog = ({ openDialog, setOpenDialog, setSubmissionsList, id, submissionDialogData, token }) => {

    const [submissionTitle, setSubmissionTitle] = useState(submissionDialogData.title);
    const [submissionType, setSubmissionType] = useState(submissionDialogData.type);
    const [submissionBullets, setSubmissionBullets] = useState(submissionDialogData.bullets);
    const [submissionMature, setSubmissionMature] = useState(submissionDialogData.mature);
    const [submissionAuthor, setSubmissionAuthor] = useState(submissionDialogData.author);
    const [submissionStatus, setSubmissionStatus] = useState('');

    const cardData = {
        title: submissionTitle,
        bullets: submissionBullets,
        mature: submissionMature,
        author: submissionAuthor,
        submissionId: id,
        type: submissionType
    }

    const handleClose = async () => {
        setOpenDialog(false);
        const updatedSubmissionsList = await getSubissionsList(token);
        setSubmissionsList(updatedSubmissionsList);
    };

    const handleChangeText = (e, setState) => {
        setState(e.target.value);
    }

    const handleTypeChange = (e, newType) => {
        if (newType.length) {
            setSubmissionType(newType);
        }
    };

    const handleBulletsChange = (e, setState, index) => {
        submissionBullets[index] = e.target.value;
        setState([...submissionBullets]);
    }

    const handleChangeSelect = (e) => {
        setSubmissionMature(e.target.value);
    }

    const handleSubmit = async () => {
        console.log(cardData)
        const newCard = await createNewCard(token, submissionType, cardData)
        if (newCard.id) {
            console.log(newCard);
            await updateSubmissionStatus(token, cardData.submissionId, 'Published');
        }
        handleClose();
    }

    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'md'} fullWidth={true} >
            <DialogTitle>Edit and Publish Submission</DialogTitle>
            <DialogContent className={'create-a-card'}>

                    <FormControl sx={{
                        m: 2, 
                        minWidth: 375,
                        '& > :not(style)': { m: 0.5}
                    }}>
                        <ToggleButtonGroup
                            value={submissionType}
                            exclusive
                            onChange={handleTypeChange}
                            aria-label="card type"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}
                            >
                            <ToggleButton value="pro" aria-label="pro card">
                                Pro
                            </ToggleButton>
                            <ToggleButton value="ohno" aria-label="ohno card">
                                Oh No
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <TextField fullWidth id='submission-title' label='Submission Title' variant='outlined' required value={submissionTitle} onChange={(e) => handleChangeText(e, setSubmissionTitle)} />
                        <TextField fullWidth id='card-bullet-1' label='First Bullet' variant='outlined' required value={submissionBullets[0]} onChange={(e) => handleBulletsChange(e, setSubmissionBullets, 0)} />
                        <TextField fullWidth id='card-bullet-2' label='Second Bullet' variant='outlined' required value={submissionBullets[1]} onChange={(e) => handleBulletsChange(e, setSubmissionBullets, 1)} />
                        <TextField fullWidth id='card-bullet-3' label='Third Bullet' variant='outlined' required value={submissionBullets[2]} onChange={(e) => handleBulletsChange(e, setSubmissionBullets, 2)} />
                        <TextField fullWidth id='card-bullet-4' label='Fourth Bullet (Optional)' variant='outlined' disabled={!submissionBullets[2]} value={submissionBullets[3]} onChange={(e) => handleBulletsChange(e, setSubmissionBullets, 3)} />
                        <TextField fullWidth id='card-bullet-5' label='Fifth Bullet (Optional)' variant='outlined' disabled={!submissionBullets[3]} value={submissionBullets[4]} onChange={(e) => handleBulletsChange(e, setSubmissionBullets, 4)} />

                        <FormGroup>
                            <FormLabel component="legend">Is this card spicy (i.e. for mature audiences only)?</FormLabel>
                            <Select
                            id="mature-select"
                            value={submissionMature}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(e) => handleChangeSelect(e)}
                            >
                                <MenuItem value={true}>Yes, 18+ spice right here!</MenuItem>
                                <MenuItem value={false}>No, absolutely no spice</MenuItem>
                            </Select>
                        </FormGroup>

                        <TextField fullWidth id='card-author' label='Author' variant='outlined' value={submissionAuthor} onChange={(e) => handleChangeText(e, setSubmissionAuthor)} />
                    </FormControl>

                    <div className={'dialog-card-preview'}>
                        <div className={submissionType}>
                            <DisplayCardText cardData={cardData} cardType={submissionType} flagTypes={[]}/>
                            <h4 className={"card-preview"}>Card Preview</h4>
                        </div>
                    </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleSubmit}>Publish</Button>
            </DialogActions>
      </Dialog>
    )

}

export default SubmissionDialog;