const Footer = () => {
    return (
        <div id='footerContainer'>
            <div id='footer'>
                <h3>What a Catch! was built by Specific Egg Media | ©2022 aSpecificEgg</h3>
            </div>
        </div>
        
    )
}

export default Footer;