import { 
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    IconButton
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import BerryLechePFP from '../Images/v2/BerryLeche_pfp.jpeg';
import EggPFP from '../Images/v2/Egg_pfp.png'

const AboutTeam = () => {


    return (
        <div className='about-team'>
            <Card sx={{ maxWidth: 340, height: 475, backgroundColor: '#9F71B5' }}>
                <CardMedia
                    sx={{ height: 240 }}
                    image={EggPFP}
                    title="Egg's profile picture"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    Egg - Game Developer
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                    Egg (she/her) is the software engineer and content creator who built What a Catch from the ground up. She originally created the game to play with her community together during Twitch streams.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" href="https://aspecificegg.me/">Links</Button>
                    <Button size="small" href="mailto:aspecificegg@gmail.com">Contact</Button>
                    <IconButton href="https://twitter.com/aSpecificEgg">
                        <TwitterIcon />
                    </IconButton>
                </CardActions>
            </Card>
            <Card sx={{ maxWidth: 340, height: 475, backgroundColor: '#9F71B5' }}>
                <CardMedia
                    sx={{ height: 240 }}
                    image={BerryLechePFP}
                    title="BerryLeche's profile picture"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    BerryLeche - Illustrator
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                    BerryLeche (she/they), aka Alex, is the incredibly skilled artist behind the graphics, art, and branding of What a Catch, including the charismatic, cute-as-a-button mascot of the game: Hamston Caesar.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" href="https://berryleche.carrd.co/">Commissions</Button>
                    <IconButton href="https://twitter.com/berrylechetwt">
                        <TwitterIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </div>
    );
}

export default AboutTeam;