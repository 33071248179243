import { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Layout from './components/Layout.js';
import GameBoard from './components/GameBoard.js';
import CreateACard from './components/CreateACard.js';
import Admin from './components/Admin.js';
import AdminLogin from './components/AdminLogin';
import AdminDash from './components/AdminDash';
import About from './components/About';
import ClosedBeta from './components/ClosedBeta';
import FrontPage from './components/FrontPage';

// Set MUI to Dark Mode
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#6352DD'
    },
    secondary: {
      main: '#FFEEB8'
    },
    ivory: {
      main: '#FFFFF2'
    },
    mode: 'dark',
  },
});

function App() {

  const [token, setToken] = useState(localStorage.getItem('wac-token'))

  return (
    <div className="App dark-mode">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
            <Route index element={<FrontPage />} />
              <Route path='play' element={<GameBoard token={token}/>} />
              <Route path='about' element={<About />} />
              <Route path='support' element={<ClosedBeta />} />
              <Route path='submit' element={<CreateACard />} />
              <Route path='PlanetSoup/' element={<Admin />}/> 
              <Route path='PlanetSoup/login' element={<AdminLogin token={token} setToken={setToken} />} />
              <Route path='PlanetSoup/dashboard' element={<AdminDash token={token} setToken={setToken} />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
