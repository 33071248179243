const BASE_URL = 'https://limitless-retreat-92378.herokuapp.com/api' || 'http://localhost:3000/api';
// const BASE_URL = 'http://localhost:3000/api';

// Select new card data from /api/[pro or ohno]/random
// cardType determines which endpoint (pro or ohno) is used
// spicy is a boolean and determines whether a card with mature content will be selected
const drawNewCard = async (cardType, spicy) => {
    const url = `${BASE_URL}/${cardType}s/random?spicy=${spicy}`;

    try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

// Submit card data as a new submission to the Millionaire Manatee API
const submitCard = async ({type, title, bullets, mature, author}) => {
    const url = `${BASE_URL}/submissions`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type,
                title,
                bullets,
                mature,
                author
            })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error)
    }

}

const loginAdmin = async (username, password) => {
    const url = `${BASE_URL}/users/login`;

    try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username,
            password
        })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

const checkIsAdmin = async (token) => {
    const url = `${BASE_URL}/users/isAdmin`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
}

const getSubissionsList = async (token) => {
    const url = `${BASE_URL}/submissions/list`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const getProsList = async (token) => {
    const url = `${BASE_URL}/pros/list`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const getOhnosList = async (token) => {
    const url = `${BASE_URL}/ohnos/list`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const getSubmissionDetailsById = async (token, id) => {
    const url = `${BASE_URL}/submissions/${id}`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const getProDetailsById = async (token, id) => {
    const url = `${BASE_URL}/pros/${id}`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const getOhnoDetailsById = async (token, id) => {
    const url = `${BASE_URL}/ohnos/${id}`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const createNewCard = async (token, cardType, cardData) => {
    const url = `${BASE_URL}/${cardType}s`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                title: cardData.title,
                bullets: cardData.bullets,
                mature: cardData.mature,
                author: cardData.author,
                submissionId: cardData.submissionId
            })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const updateSubmissionStatus = async (token, id, submissionStatus) => {
    const url = `${BASE_URL}/submissions/${id}`;

    try {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                status: submissionStatus
            })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const updateCardContent = async (token, cardType, id, cardData) => {
    const url = `${BASE_URL}/${cardType}s/${id}`;

    try {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                title: cardData.title,
                bullets: cardData.bullets,
                mature: cardData.mature,
                author: cardData.author
            })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const deactivateCard = async (token, cardType, id) => {
    const url = `${BASE_URL}/${cardType}s/deactivate/${id}`;

    try {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }    
}

const activateCard = async (token, cardType, id) => {
    const url = `${BASE_URL}/${cardType}s/activate/${id}`;

    try {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }    
}

const getFlagTypes = async () => {
    const url = `${BASE_URL}/flags/types`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const createFlag = async (cardType, cardId, typeId) => {
    const url = `${BASE_URL}/flags`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                cardType,
                cardId,
                typeId
            })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

const deleteFlag = async (token, flagId) => {
    const url = `${BASE_URL}/flags/${flagId}`;

    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

export {
    drawNewCard,
    submitCard,
    loginAdmin,
    checkIsAdmin,
    getSubissionsList,
    getProsList,
    getOhnosList,
    getSubmissionDetailsById,
    getProDetailsById,
    getOhnoDetailsById,
    createNewCard,
    updateSubmissionStatus,
    updateCardContent,
    deactivateCard,
    activateCard,
    getFlagTypes,
    createFlag,
    deleteFlag
};